@import '../node_modules/@salesforce-ux/c360-styling-hooks/dist/hooks.custom-props.css';
@import '../node_modules/@salesforce-ux/c360-styling-hooks/dist/kx.custom-props.css';

:root {
  --nav-integration-c-header-height-desktop: 96px;
  --c360-nav-c-overlay-z-index: 400;
  --c360-nav-c-padding-top: 138px;

  font-family: var(--c360-g-font-family-display);
  font-weight: normal;
}

section.panel-nav {
  background: lightskyblue;
  z-index: calc(var(--c360-nav-c-overlay-z-index) + 1);
  height: calc(100vh - var(--nav-integration-c-header-height-desktop));
  transform: translateX(-100%);
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
  visibility: hidden;
  display: flex;
  flex-direction: row;
  left: 0;
  width: auto;

  h2 {
    margin: 56px auto 28px 20px;
    font-size: 40px;
    line-height: 40px;
    font-family: var(--c360-g-font-family-display);
    font-weight: normal;
    color: #032d60;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 9px;
    margin-right: 40px;
    padding-bottom: 40px;
    display: block;
  }

  .panel-nav__item {
    height: 48px;
    font-size: var(--c360-g-font-size-3);
    margin-left: 12px;
    margin-right: 20px;
    display: block;
    line-height: 28px;
    min-height: 48px;
    max-width: 367px;
    margin-top: 0px;
    margin-right: 0px;
  }

  .panel-a {
    box-sizing: border-box;
    margin-left: 0px;
    padding-left: 128px;
    display: table-cell;
    vertical-align: top;
    overflow-y: auto;
    height: unset;
    flex-grow: 1;
  }

  .panel-b {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - var(--nav-integration-c-header-height-desktop));
  }
}